
import { useWallet } from "@/solana/externalWallet";
import { defineComponent } from "vue";
export default defineComponent({
  emits: ["update:address"],
  props: {
    address: {
      type: String,
      default: ""
    },
    derivePublicKey: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ""
    }
  },
  setup(_, { emit }) {
    const onDerivePublicKey = async () => {
      const wallet = await useWallet();
      emit("update:address", wallet.publicKey.toBase58());
    };

    return { onDerivePublicKey };
  }
});
