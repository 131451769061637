
import Heading from "@/components/util/Heading.vue";
import PublicKeyFormField from "@/components/util/PublicKeyFormField.vue";
import CopyIcon from "@/components/util/CopyIcon.vue";
import { requestAirdrop } from "@/solana/airdrop";
import { chosenCluster } from "@/solana/connection";
import * as SolanaErrorHandler from "@/solana/SolanaErrorHandler";

import { computed, defineComponent, ref } from "vue";
export default defineComponent({
  components: {
    Heading,
    PublicKeyFormField,
    CopyIcon
  },
  setup() {
    const addressToAirdrop = ref("");
    const requestingAirdrop = ref(false);
    const amount = ref(10);
    const addressThatReceivedAirdrop = ref("");
    const accountLink = ref("");
    const errorMessage = ref("");

    const onRequestAirdrop = async () => {
      requestingAirdrop.value = true;
      errorMessage.value = "";
      accountLink.value = "";
      const addressStatic = addressToAirdrop.value;
      addressThatReceivedAirdrop.value = "";
      try {
        await requestAirdrop(addressToAirdrop.value, amount.value);
        addressThatReceivedAirdrop.value = addressStatic;
        accountLink.value = `https://explorer.solana.com/address/${addressThatReceivedAirdrop.value}?cluster=${chosenCluster.value}`;
      } catch (err) {
        errorMessage.value = SolanaErrorHandler.getErrorMessage(err);
      }
      requestingAirdrop.value = false;
    };

    const notMainnet = computed(() => chosenCluster.value !== "mainnet-beta");

    return {
      addressToAirdrop,
      requestingAirdrop,
      amount,
      onRequestAirdrop,
      accountLink,
      errorMessage,
      addressThatReceivedAirdrop,
      notMainnet
    };
  }
});
